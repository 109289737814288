<template>
  <b-modal
    id="modal-form"
    :title="type === 'add' ? 'Agregar Consejo' : 'Editar Consejo'"
    no-close-on-backdrop
    hide-footer
  >
    <b-alert v-model="showDismissibleAlert" variant="danger" dismissible>
      <div class="iq-alert-text">
        <h5 class="alert-heading">Errores</h5>
        <p>
          <ol v-for="error in errorMessages" :key="error">
            <li>{{ error }}</li>
          </ol>
        </p>
      </div>
    </b-alert>
    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
      <b-form @submit.stop.prevent="handleSubmit(onSubmit)">
        <b-row>
          <b-form-group class="col-md-12" label="Título:">
            <ValidationProvider name="Título" rules="required" v-slot="{ errors }">
              <b-form-input
                v-model="form.title"
                type="text"
                :class="errors.length > 0 ? ' is-invalid' : ''"
              />
              <div class="invalid-feedback">
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </b-form-group>
          <b-form-group class="col-md-12" label="Fecha:">
            <ValidationProvider name="Fecha" rules="required" v-slot="{ errors }">
              <b-form-input
                v-model="form.date"
                type="date"
                :class="errors.length > 0 ? ' is-invalid' : ''"
              />
              <div class="invalid-feedback">
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </b-form-group>
          <b-form-group class="col-md-12" label="URL:">
            <ValidationProvider name="URL" rules="required" v-slot="{ errors }">
              <b-form-input
                v-model="form.url"
                type="text"
                :class="errors.length > 0 ? ' is-invalid' : ''"
              />
              <div class="invalid-feedback">
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </b-form-group>
          <div class="col-md-12 w-100">
            <b-button type="submit" variant="primary" class="float-right">
              {{ type === "add" ? "Guardar" : "Actualizar" }}
            </b-button>
          </div>
        </b-row>
      </b-form>
    </ValidationObserver>
  </b-modal>
</template>

<script>
import { Toast } from './../../../mixins/toast'
import api from './../../../services/api'

export default {
  components: {},
  props: {
    type: {
      type: String,
      required: true,
      default: 'add'
    },
    data: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      form: {
        title: null,
        date: null,
        url: null
      },
      errorMessages: [],
      showDismissibleAlert: false
    }
  },
  watch: {
    data: function (newValue, oldValue) {
      this.form = newValue
    }
  },
  methods: {
    onSubmit () {
      if (this.type === 'add') {
        this.saveData()
      } else {
        this.updateData()
      }
    },
    async saveData () {
      try {
        await api.post('/tips', this.form)

        Toast.fire({
          icon: 'success',
          title: 'Consejo Guardado'
        })

        this.$bvModal.hide('modal-form')
        this.$emit('getData')
        this.errorMessages = []
      } catch (error) {
        if (error && error.data && error.data.errors) {
          this.showDismissibleAlert = true
          this.errorMessages = []

          for (let field in error.data.errors) {
            this.errorMessages.push(...error.data.errors[field])
          }

          this.$bvToast.toast(this.errorMessages.join('<br>'), {
            title: 'Error',
            variant: 'danger',
            solid: true,
            autoHideDelay: 5000
          })
        } else {
          this.$bvToast.toast('Ha ocurrido un error', {
            title: 'Error',
            variant: 'danger',
            solid: true,
            autoHideDelay: 5000
          })
        }
      }
    },
    async updateData () {
      try {
        await api.put(`/tips/${this.data.id}`, this.form)

        Toast.fire({
          icon: 'success',
          title: 'Consejo Actualizado'
        })

        this.$bvModal.hide('modal-form')
        this.$emit('getData')
        this.errorMessages = []
      } catch (error) {
        if (error && error.data && error.data.errors) {
          this.showDismissibleAlert = true
          this.errorMessages = []

          for (let field in error.data.errors) {
            this.errorMessages.push(...error.data.errors[field])
          }

          this.$bvToast.toast(this.errorMessages.join('<br>'), {
            title: 'Error',
            variant: 'danger',
            solid: true,
            autoHideDelay: 5000
          })
        } else {
          this.$bvToast.toast('Ha ocurrido un error', {
            title: 'Error',
            variant: 'danger',
            solid: true,
            autoHideDelay: 5000
          })
        }
      }
    }
  }
}
</script>
